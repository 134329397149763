// @import "custom";

// @font-face {
//     font-family: 'promptsemibold';
//     src: url(./assets/fonts/prompt-semibold-webfont.woff2) format('woff2'),
//          url(./assets/fonts/prompt-semibold-webfont.woff) format('woff');
//     font-weight: normal;
//     font-style: normal;

// }

// @font-face {
//     font-family: 'promptregular';
//     src: url(./assets/fonts/prompt-regular-webfont.woff2) format('woff2'),
//          url(./assets/fonts/prompt-regular-webfont.woff) format('woff');
//     font-weight: normal;
//     font-style: normal;

// }

// body,html {
//     font-family: 'promptregular' !important;
// }
// .btn, h1,h2,h3,h4,h5,h6  {
//     font-family: 'promptsemibold' !important;
// }
// .btn {
//     &.btn-secondary {
//         color: #fff;
//     }
// }
// .App {
//     max-width: 414px;

//     .otp-page {
//         img {
//             width: 70%;
//             margin: 0 auto;
//         }
//     }
// }

*:focus {
    outline: none;
}
input[type=text] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input:focus {outline: 0;}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

select {
  padding: 20px;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}

.select-section {
  position: relative;
  select {
    height: 46px;
  }
  &:before {
    content: " ";
    position: absolute;
    top: 50%;
    margin-top: -2px;
    right: 8px;
    width: 0;
    height: 0;
    border-left: .3rem solid transparent;
    border-right: .3rem solid transparent;
    border-top: .3rem solid #38373A;
    z-index: 10;
  }
}

body,
html {
  background-color: #fff;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
}

.spinner-border {
    vertical-align: -0.125em;
    border: 0.25em solid;
    border-right-color: transparent;
}

.visually-hidden {
    position: absolute!important;
    width: 1px!important;
    height: 1px!important;
    padding: 0!important;
    margin: -1px!important;
    overflow: hidden!important;
    clip: rect(0,0,0,0)!important;
    white-space: nowrap!important;
    border: 0!important;
}

.otp-input {
    input {
        margin: 0 auto;
    }
}

.Modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #fff;
  width: 90%;
  max-height: fit-content;
  padding: 24px 24px 36px;
  border-radius: 12px;
  text-align: center;
}

.Overlay {
  z-index: 40;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
}

.overlay2 {
  z-index: 11;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
}

/* The container */
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: .9rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #616161;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.container-checkbox .checkmark {
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  height: 1.3rem;
  width: 1.3rem;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: #283D78;
}

/* Create the checkmark/indicator (hidden when not checked) */
.container-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  margin: auto;
  left: 0;
  right: 0;
  top: -2px;
  bottom: 0;
  width: 7px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);

}

/* The container-radio-btn */
.container-radio-btn {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  // font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 1.5rem;
}

/* Hide the browser's default radio button */
.container-radio-btn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.container-radio-btn .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #BDBDBD;
}

/* On mouse-over, add a grey background color */
.container-radio-btn:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container-radio-btn input:checked ~ .checkmark {
  background-color: #283D78;
  border-color: #283D78;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.container-radio-btn .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio-btn input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-radio-btn .checkmark:after {
      top: 5px;
      left: 5px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
}

.ic-email {
  &.true {
    display: none;
  }
}

.ic-email-required {
  &.false {
    display: none;
  }
}

#map { 
  .leaflet-container {
    width: 100%;
    .leaflet-control-attribution {
      visibility: hidden;
    }
    .leaflet-popup {
      width: 300px;
    }
  }
}

input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  content: URL('./assets/img/ic-delete.svg');
  cursor: pointer;
}

.react-datepicker__portal {
  background-color: rgba(0,0,0,0.5) !important;
}
.react-datepicker__navigation {
  .react-datepicker__navigation-icon--next::before {
    top: 18px;
  }

  .react-datepicker__navigation-icon--previous::before {
    top: 18px;
  }
}

.Modal {
  &.modal-calendar {
    padding: 0;
  }
}

.react-date-picker, .react-datetime-picker {
  /* bring your own prefixes */
  // transform: translate(50%, -50%);
  // margin: 50% 30px auto;
    border-radius: 12px 12px 0 0;
  .react-calendar__tile--active {
    &:enabled{
      &:hover {
        color: #fff;
      }
    }
  }

  .react-date-picker__calendar, .react-datetime-picker__calendar{
    // position: absolute;
    position: static !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    z-index: 99;
    width: 100%;
    border-radius: 8px 8px 0 0;
  }

  .react-calendar {
    // width: 320px !important;
    // margin: auto 4px;
    width: 100%;
    margin: auto;
    border: none;
    border-bottom: 1px solid #eee;
    border-radius: 8px 8px 0 0;
    // border-radius: 8px;
    // box-shadow: 0px 2px 3px 2px rgba(0,0,0,0.15);
    // -webkit-box-shadow: 0px 2px 3px 2px rgba(0,0,0,0.15);
    // -moz-box-shadow: 0px 2px 3px 2px rgba(0,0,0,0.15);
    .react-calendar__tile--active {
      border-radius: 8px;
    }
  }
  p, span {
    font-family: 'promptsemibold';
  }
  abbr {
    font-family: 'promptregular';
  }
  .react-calendar__navigation {
    margin-bottom: 0;
  }
  .react-calendar__navigation__label {
    span {
      font-size: .95rem;
    }
  }
  .react-calendar__month-view {
    padding: 0 10px 10px
  }
  .react-date-picker__wrapper, .react-datetime-picker__wrapper {
    display: none;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #aaa;
  }
  .react-calendar__tile:disabled, .react-calendar__navigation button:disabled {
    color: #ccc;
    background-color: transparent;
    abbr {
      color: #ccc;
    }
  }
  .react-calendar__tile--now {
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid #006edc;
    color: #006edc;
  }
}

.Modal {
  padding: .5rem 1rem .75rem;
}

.display-text-group {
  .displayed-text {
    color: #616161;
  }
  .read-more-button {
    display: inline-block;
    color: #199690;
    font-family: 'promptsemibold';
    font-size: 1rem;
  }
}

.listcoupon {
  .listcoupon__menu > div {
    & > div {
      // color: #616161;
      padding-left: 40px;
      &::before {
        content: '●';
        position: absolute;
        left: 20px;
      }
    }
  }
}


.AppVdoCall {
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  justify-items: center;
  align-items: center;
  height: 90vh;

  .heading {
    text-align: center;
    align-self: flex-start;
    margin-bottom: 0;
  }

  #videos{
    position: relative;
    height: 85vh;
    width: 100vw;
    margin: auto;
    align-self: flex-start;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
    justify-items: center;
    align-items: center;
  }

  .vid{
    position: relative;
    background-color:black;
    height: 95%;
    width: 95%;
    border-width: 1px;
    border-color: #38373A;
    border-style: solid;
  }

  .controls{
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    margin: -20px auto;
    display: grid;
    grid-template-columns: repeat(3, 33%);
    align-items: center;
    justify-items: center;
    z-index: 1;
    width: 500px;
    max-width: 60vw;
  }

  .controls p{
    padding: 10px;
    cursor: pointer;
    background: #38373A;
    color: #F7F7F7;
    border-width: 1px;
    border-color: #F7F7F7;
    border-style: solid;
  }

  .controls p.on{
    background: #F7F7F7;
    color: #38373A;
    border-width: 1px;
    border-color: #38373A;
    border-style: solid;
  }

  .join{
    position: absolute;
    z-index: 1;
    width: 30vw;
    height: fit-content;
    height: -moz-max-content;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    width: 500px;
    max-width: 75vw;
  }

  .join  input{
    padding: 15px;
    font-size: 1rem;
    border-width: 1px;
    border-color: #38373A;
    border-style: solid;
    width: 80%;
    display: block;
    margin:  50px auto;
  }

  .join  button{
    min-width: 200px;
    padding: 12px 0;
    text-align: center;
    background-color: #38373A;
    color: #F7F7F7;
    border-width: 1px;
    border-color: #F7F7F7;
    border-style: solid;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    display: block;
    margin: 0 auto;
  }

}

@media (max-width: 320px) {
  .react-date-picker, .react-datetime-picker { 
    .react-calendar__navigation__label { 
      span {
        font-size: .8em;
      }
    }
  }
}